<template>
  <div>
    <b-button
      size="sm"
      variant="primary"
      @click="openModal"
    >
      <feather-icon icon="PlusIcon" />

      {{ $t('Add') }}
    </b-button>

    <app-collapse>
      <draggable v-model="fieldList"
                 @end="savePositions"
      >
        <app-collapse-item v-for="(item, index) in fieldList"
                           :key="`addon_field_${index}`"
                           arrow-left
                           off
                           show-actions
                           show-edit
                           :show-copy="false"
                           show-delete
                           @edit="editField(item)"
                           @delete="removeField(item.id)"
        >
          <template #subTitle>
            <div class="ml-1 d-flex mt-50">
              <b-badge variant="primary">
                <span class="font-weight-bolder font-small-1">{{ $t(`offer.attribute.types.${item.type}`) }}</span>
              </b-badge>
              <div v-if="item.contactAddonFieldTypeOptions && item.contactAddonFieldTypeOptions.length">
                <feather-icon icon="ArrowRightIcon"
                              class="mx-50"
                />
                <b-badge
                  v-for="option in item.contactAddonFieldTypeOptions"
                  :key="option.id"
                  variant="light-primary"
                  class="mr-25"
                >
                  {{ option.name }}
                </b-badge>
              </div>
            </div>
          </template>
          <template #title>
            <feather-icon
              icon="MoveIcon"
              class="text-primary mr-25"
              size="18"
            />
            {{ item.name }}
            <feather-icon v-if="item.description"
                          v-b-tooltip
                          icon="AlertCircleIcon"
                          :title="item.description"
            />
          </template>
        </app-collapse-item>
      </draggable>
    </app-collapse>

    <!--    <b-table-->
    <!--      ref="selectableTable"-->
    <!--      :items="fieldList"-->
    <!--      class="table-wrap-words mt-1"-->
    <!--      :fields="fields"-->
    <!--      striped-->
    <!--      responsive-->
    <!--      :busy="isLoading"-->
    <!--      show-empty-->
    <!--    >-->
    <!--      <template #head()="{ label }">-->
    <!--        {{ $t(`${label}`) }}-->
    <!--      </template>-->

    <!--      <template #cell(type)="{ value }">-->
    <!--        {{ $t(`offer.attribute.types.${value}`) }}-->
    <!--      </template>-->

    <!--      <template #cell(contactAddonFieldTypeOptions)="{ value }">-->
    <!--        <div-->
    <!--          class="d-flex"-->
    <!--          style="gap: .5rem"-->
    <!--        >-->
    <!--          <b-badge-->
    <!--            v-for="option in value"-->
    <!--            :key="option.id"-->
    <!--            variant="light-primary"-->
    <!--          >-->
    <!--            {{ option.name }}-->
    <!--          </b-badge>-->
    <!--        </div>-->
    <!--      </template>-->

    <!--      <template #cell(action)="{ item }">-->
    <!--        <b-button-->
    <!--          size="sm"-->
    <!--          variant="flat-primary"-->
    <!--          class="btn-icon"-->
    <!--          @click="editField(item)"-->
    <!--        >-->
    <!--          <feather-icon-->
    <!--            icon="SettingsIcon"-->
    <!--            size="14"-->
    <!--          />-->
    <!--        </b-button>-->

    <!--        <b-button-->
    <!--          size="sm"-->
    <!--          variant="flat-danger"-->
    <!--          class="btn-icon"-->
    <!--          @click="removeField(item.id)"-->
    <!--        >-->
    <!--          <feather-icon-->
    <!--            icon="XIcon"-->
    <!--            size="14"-->
    <!--          />-->
    <!--        </b-button>-->
    <!--      </template>-->

    <!--      <template #empty>-->
    <!--        <div-->
    <!--          class="text-center py-1 text-primary font-weight-bold"-->
    <!--        >-->
    <!--          <feather-icon-->
    <!--            size="17"-->
    <!--            icon="XCircleIcon"-->
    <!--          />-->
    <!--          {{ $t('NoData') }}-->
    <!--        </div>-->
    <!--      </template>-->

    <!--      <template #table-busy>-->
    <!--        <div class="text-center text-danger my-2">-->
    <!--          <b-spinner-->
    <!--            class="align-middle"-->
    <!--            variant="primary"-->
    <!--          />-->
    <!--        </div>-->
    <!--      </template>-->
    <!--    </b-table>-->

    <!--    Modal: Add Field     -->
    <b-modal
      v-model="showFieldModal"
      :title="$t('Field')"
      size="md"
      hide-footer
      no-close-on-backdrop
      @close="clearModal"
    >
      <validation-observer
        ref="newFieldForm"
        v-slot="{ invalid }"
        tag="form"
      >
        <!--      New Filed: Type      -->
        <validation-provider
          v-slot="{ errors }"
          rules="required"
          :name="$t('task.Type')"
        >
          <b-form-group>
            <sw-select :name="$t('task.Type')">
              <v-select
                v-model="newField.type"
                :placeholder="$t('task.Type')"
                :options="typeList"
                :filterable="false"
                label="name"
                :state="errors.length > 0 ? false:null"
              >
                <template #option="{ name }">
                  {{ $t(`offer.attribute.types.${name}`) }}
                </template>

                <template #selected-option="{ name }">
                  {{ $t(`offer.attribute.types.${name}`) }}
                </template>

                <template #no-options>
                  {{ $t('NoOptions') }}
                </template>
              </v-select>
            </sw-select>

            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <!--      New Filed: Name      -->
        <validation-provider
          v-slot="{ errors }"
          rules="required"
          :name="$t('Name')"
        >
          <b-form-group :label="$t('Name')">
            <b-form-input
              v-model="newField.name"
              :placeholder="$t('Name')"
              :state="errors.length > 0 ? false:null"
            />

            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <!--      New Filed: Options      -->
        <div v-if="['RADIAL', 'TEXT_ARRAY', 'TEXT_MULTIPLE_ARRAY'].includes(newField.type)">
          <b-form-group :label="$t('Value')">
            <b-input-group>
              <b-form-input
                v-model="newOption"
                :placeholder="$t('Value')"
              />

              <b-input-group-append>
                <b-button
                  variant="outline-primary"
                  class="btn-icon"
                  @click="() => {
                    newField.contactAddonFieldTypeOptions.push({ name: newOption })
                    newOption = ''
                  }"
                >
                  <feather-icon icon="PlusIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>

          <b-form-group
            v-for="(option, i) in newField.contactAddonFieldTypeOptions"
            :key="i"
          >
            <b-input-group>
              <b-form-input
                :value="option.name"
                :placeholder="$t('Value')"
                disabled
              />

              <b-input-group-append>
                <b-button
                  variant="outline-danger"
                  class="btn-icon"
                  @click="newField.contactAddonFieldTypeOptions.splice(i, 1)"
                >
                  <feather-icon icon="MinusIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>

        <!--      New Filed: Description      -->
        <validation-provider
          v-slot="{ errors }"
          :name="$t('Description')"
        >
          <b-form-group :label="$t('Description')">
            <b-form-textarea
              v-model="newField.description"
              :placeholder="$t('Description')"
              :state="errors.length > 0 ? false:null"
              maxlength="110"
            />

            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <!--      Button: Save      -->
        <b-button
          size="sm"
          variant="primary"
          :disabled="invalid"
          @click="addField"
        >
          {{ $t('Save') }}
        </b-button>

        <b-overlay
          no-wrap
          :show="isLoading"
          spinner-variant="primary"
        />
      </validation-observer>
    </b-modal>

    <b-overlay
      :show="isLoading"
      no-wrap
      spinner-variant="primary"
    />
  </div>
</template>

<script>
import {
  BFormInput, BFormTextarea, BInputGroup, BInputGroupAppend, BTab, BTabs, VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { contactInfoTypes } from '@/helpers/offer/attributeTypes'
import {
  CREATE_CONTACT_ADDON_FIELD_TYPE,
  DELETE_CONTACT_ADDON_FIELD_TYPE,
  GET_CONTACT_ADDON_FIELD_TYPES,
  UPDATE_CONTACT_ADDON_FIELD_TYPE,
} from '@/@constants/mutations'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import draggable from 'vuedraggable'

export default {
  components: {
    vSelect,
    ValidationObserver,
    ValidationProvider,
    BFormInput,
    BFormTextarea,
    // eslint-disable-next-line vue/no-unused-components
    BTabs,
    // eslint-disable-next-line vue/no-unused-components
    BTab,
    BInputGroup,
    BInputGroupAppend,
    AppCollapse,
    AppCollapseItem,
    draggable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data: () => ({
    isLoading: false,

    showFieldModal: false,

    langList: ['PL', 'EN', 'DE'],

    fieldList: [],
    fields: [
      { key: 'name', label: 'Name', visible: true },
      { key: 'type', label: 'task.Type', visible: true },
      { key: 'description', label: 'Description', visible: true },
      { key: 'contactAddonFieldTypeOptions', label: 'Options', visible: true },
      { key: 'action', label: 'Action', visible: true },
    ],

    typeList: (contactInfoTypes)(),

    newOption: '',

    newField: {
      id: null,
      type: '',
      name: '',
      contactAddonFieldTypeOptions: [],
      description: '',
    },
  }),

  // watch: {
  //   showFieldModal(n) { if (!n) this.loadTypes() },
  // },

  mounted() { this.ini() },

  methods: {
    ini() { this.loadTypes() },

    openModal() { this.showFieldModal = true },

    closeModal() {
      this.clearModal()

      this.showFieldModal = false
    },

    addField() {
      if (this.validationForm()) {
        const payload = this.getPayload()

        if (payload) this.saveField(payload)
      }
    },

    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.newFieldForm.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },

    getPayload() {
      const {
        id, type, name, description, contactAddonFieldTypeOptions,
      } = this.newField

      const payload = {
        type,
        name,
        description,
      }

      if (['RADIAL', 'TEXT_ARRAY', 'TEXT_MULTIPLE_ARRAY'].includes(type)) payload.contactAddonFieldTypeOptions = contactAddonFieldTypeOptions.map(o => ({ ...o, name: o.name })).filter(Boolean)

      if (id) payload.id = id

      return payload
    },

    savePositions() {
      const payload = this.fieldList.map((element, index) => ({ id: element.id, position: index }))
      this.$store.dispatch('addonFieldTypes/UPDATE_CONTACT_ADDON_FIELD_TYPE', payload)
      // eslint-disable-next-line no-unused-vars
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },

    saveField(payload) {
      this.isLoading = true

      const type = !payload.id ? CREATE_CONTACT_ADDON_FIELD_TYPE : UPDATE_CONTACT_ADDON_FIELD_TYPE

      this.$store.dispatch(`addonFieldTypes/${type}`, payload)
        // eslint-disable-next-line no-unused-vars
        .then(res => {
          this.showToast('success', this.$i18n.t(payload?.id ? 'FieldTypeUpdated' : 'FieldTypeAdded'))

          // if (!payload.id) {
          //   this.fieldList.push({ id: payload.id || res, ...payload })
          // } else {
          //   this.fieldList[this.fieldList.findIndex(field => field.id === payload.id)] = payload
          // }

          this.closeModal()

          this.loadTypes()
        })

        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })

        .finally(() => { this.isLoading = false })
    },

    loadTypes() {
      this.isLoading = true

      this.$store.dispatch(`addonFieldTypes/${GET_CONTACT_ADDON_FIELD_TYPES}`)
        .then(res => { this.$set(this, 'fieldList', res) })

        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })

        .finally(() => { this.isLoading = false })
    },

    // eslint-disable-next-line consistent-return
    async removeField(id) {
      const confirmed = await this.showAlert('warning', this.$i18n.t('alerts.AreYouSure'))
      if (!confirmed) return false

      this.$store.dispatch(`addonFieldTypes/${DELETE_CONTACT_ADDON_FIELD_TYPE}`, id)
        .then(() => {
          this.fieldList.splice(this.fieldList.findIndex(el => el.id === id), 1)
        })

        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },

    editField(field) {
      const {
        id,
        type,
        name,
        contactAddonFieldTypeOptions,
        description,
      } = JSON.parse(JSON.stringify(field))

      this.newField = {
        id,
        type,
        name,
        contactAddonFieldTypeOptions,
        description,
      }

      this.openModal()
    },

    clearModal() {
      this.newField = {
        id: null,
        type: '',
        name: '',
        contactAddonFieldTypeOptions: [],
        description: '',
      }
    },
  },
}
</script>
